code,
samp,
time {
  background: var(--background);
  color: var(--code);
  padding: 2.5px 5px;
  border-radius: 6px;
  font-size: 1em;
}

pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}

var {
  color: var(--variable);
  font-style: normal;
  font-family: monospace;
}

kbd {
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 2px;
  color: var(--text-main);
  padding: 2px 4px 2px 4px;
}
