*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Avenir, "Avenir Next", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;

  color: var(--text-main);
  background: var(--background-body);

  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-size: 1em;
}
