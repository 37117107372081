:root {
  --white: #fff;
  --grey: #e6e6e6;
  --black: #262626;
  --green: #06847d;
  --blue: #4a69bd;
  --orange: #a46313;
  --red: #e02e06;
  --primary: var(--blue);
  --secondary: var(--green);
  --background: #fff;
  --background-alt: #f8fafc;
  --selection: #9e9e9e;
  --text-main: #4a4a4a;
  --text-bright: #000;
  --text-muted: #707070;
  --links: var(--primary);
  --focus: rgba(74, 105, 189, .35);
  --border: rgba(0, 0, 0, .2);
  --border-radius: 4px;
  --code: #000;
  --animation-duration: .1s;
  --button-hover: #ddd;
  --button-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  --form-placeholder: #949494;
  --form-text: #000;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='62.5' width='116.9' fill='%23161f27'%3E%3Cpath d='M115.3 1.6c-1.6-1.6-4.2-1.6-5.8 0l-51 51.1L7.4 1.6C5.8 0 3.2 0 1.6 1.6 0 3.2 0 5.8 1.6 7.4l53.9 53.9c.8.8 1.8 1.2 2.9 1.2 1 0 2.1-.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2.1-5.8z'/%3E%3C/svg%3E");
  --heading-margin: .5rem 0 .75rem;
  --typo-size-1: 2.8rem;
  --typo-size-2: 2.2rem;
  --typo-size-3: 1.8rem;
  --typo-size-4: 1.4rem;
  --typo-size-5: 1.2rem;
  --typo-size-6: 1rem;

  --accent-border: 0;
  --accent-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}
*,
::before,
::after {
  box-sizing: border-box;
}
html {
  font-family: -apple-system, BlinkMacSystemFont, Avenir, "Avenir Next", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;

  color: var(--text-main);
  background: var(--background-body);

  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
body {
  margin: 0;
  font-size: 1em;
}
code,
samp,
time {
  background: var(--background);
  color: var(--code);
  padding: 2.5px 5px;
  border-radius: 6px;
  font-size: 1em;
}
pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}
var {
  color: var(--variable);
  font-style: normal;
  font-family: monospace;
}
kbd {
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 2px;
  color: var(--text-main);
  padding: 2px 4px 2px 4px;
}
button,
.btn,
input,
textarea {
  transition: background-color var(--animation-duration) linear,
    border-color var(--animation-duration) linear,
    color var(--animation-duration) linear,
    transform var(--animation-duration) ease;
}
button,
.btn,
select,
input[type="submit"],
input[type="button"],
input[type="checkbox"],
input[type="range"],
input[type="radio"] {
  line-height: 1em;
  cursor: pointer;
  white-space: nowrap;
}
input:not([type="checkbox"]):not([type="radio"]),
select {
  display: block;
}
input,
button,
textarea,
select,
details > summary,
.tag,
.btn {
  color: var(--form-text);
  background-color: var(--background);

  font-family: inherit;
  font-size: inherit;

  position: relative;
  margin: .5rem .5rem .5rem 0;
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;

  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  outline: none;

  appearance: none;
}
textarea {
  margin-right: 0;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}
select {
  background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
  padding-right: 35px;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  padding-right: 10px;
  background-image: none;
  overflow-y: auto;
}
button,
.btn,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  padding-right: 28px;
  padding-left: 28px;
  width: auto;

  background-color: var(--btn-bg, var(--primary));
  color: var(--btn-c, var(--white));
  box-shadow: var(--button-shadow);
}
button:hover,
.btn:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  box-shadow: inset 0 0 2rem rgba(0, 0, 0, .25);
}
input:not([readonly]):hover,
select:not([readonly]):hover,
textarea:not([readonly]):hover {
  border-color: rgba(100, 100, 100, .75);
}
input[type='checkbox']:active,
input[type='radio']:active,
input[type='submit']:active,
input[type='button']:active,
input[type='range']:active,
button:active {
  background-image: linear-gradient(rgba(255, 255, 255, .1), rgba(255, 255, 255, .1));
}
input:focus,
select:focus,
button:focus,
textarea:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px var(--focus);
}
input[type="checkbox"],
input[type="radio"] {
  position: relative;
  width: 20px;
  min-width: auto;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  padding: 1px;
  margin: 0;
  margin-right: 2px;
}
input[type="radio"] {
  border-radius: 50%;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, .4), inset 0 0 0 4px #fff;
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background: var(--primary);

}
input[type="checkbox"]:checked::before {
  display: flex;
  justify-content: center;

  content: "✔";

  color: white;
  font-size: .8rem;
}
input[type="range"] {
  padding: 0;
}
input[type="color"] {
  padding: 2px;
  width: 5rem;
  height: 2.5rem;
}
input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: .5;
}
::placeholder {
  color: var(--form-placeholder);
}
legend {
  font-size: .9em;
  font-weight: 600;
}
fieldset {
  border: 1px var(--border) solid;
  border-radius: var(--border-radius);
  margin: 0;
  margin-block-end: .5rem;
  padding: .75rem 1.5rem;
}
fieldset > legend:first-of-type {
  padding: 0 .5rem;
  font-size: 1.1rem;
  font-weight: normal;
}
.tag {
  align-items: center;
  display: inline-flex;

  font-size: .8rem;
  color: var(--tag-c, var(--white));
  background-color: var(--tag-bg, var(--primary));

  width: auto;
  height: 2em;
  justify-content: center;
  padding: 0 .75em 2px;

  border: 0;
  box-shadow: none;
}
.group {
  display: inline-flex;
  justify-self: flex-start;
  margin: .5rem 1rem .5rem 0;
}
.group > * {
  margin: 0;
}
.group:not(.vertical) > * + * {
  margin-inline-start: -1px;
}
.group:not(.vertical) > *:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group:not(.vertical) > *:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.group.vertical {
  flex-direction: column;
}
.group.vertical > * + * {
  margin-block-start: -1px;
}
.group.vertical > *:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.group.vertical > *:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(var(--col, 1), 1fr);
  grid-gap: var(--gap, 1rem);
}
img {
  max-width: 100%;
  height: auto;
}
hr {
  border: none;
  border-top: 1px solid var(--border);
}
::selection {
  background-color: var(--selection);
}
footer {
  font-size: .8em;
  color: var(--text-muted);
}
svg {
  display: inline-block;
  font-size: inherit;
  height: 1.2em;
  vertical-align: -.2em;
  overflow: visible;
}
figure {
  display: inline-flex;
  flex-direction: column;
  margin: 0;
}
figure figcaption {
    padding: .75rem 1.5rem;
    margin: 0;
  }
table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}
caption {
  padding: .5rem 0;
  text-align: start;
  caption-side: top;
}
td,
th {
  padding: .5rem;
  text-align: start;
}
th {
  background: rgba(100,100,100,.025);
}
thead {
  border-bottom: 1px solid rgba(100,100,100,.2);
}
tr + tr,
tfoot {
  border-top: 1px solid rgba(100,100,100,.2);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.3em;
  margin: var(--heading-margin);
  color: var(--text-c, inherit);
}
h1 {
  font-size: var(--typo-size-1);
}
h2 {
  font-size: var(--typo-size-2);
}
h3 {
  font-size: var(--typo-size-3);
}
h4 {
  font-size: var(--typo-size-4);
}
h5 {
  font-size: var(--typo-size-5);
}
h6 {
  font-size: var(--typo-size-6);
  line-height: 1.6em;
}
p,
table {
  margin: 0 0 1rem
}
a {
  color: var(--links);
  text-decoration: none;
}
b,
strong,
th {
  font-weight: bold;
}
blockquote {
  border-left: 4px solid var(--primary);
  background-color: #fff;
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  font-style: italic;
}
blockquote > footer {
  font-style: normal;
  border: 0;
}
blockquote cite {
  font-style: normal;
}
ul,
ol {
  padding: 0;
  padding-inline-start: 2rem;
}
ul {
  list-style: disc;
}
dt {
  font-weight: bold;
}
dd {
  margin: 0 1rem;
}
address {
  font-style: normal;
}
mark {
  background-color: var(--highlight);
  border-radius: 2px;
  padding: 0 2px 0 2px;
}
.primary {
  --text-c: var(--primary);
  --btn-bg: var(--primary);
  --tag-bg: var(--primary);
  --accent-bg: var(--primary);
}
.secondary {
  --text-c: var(--secondary);
  --btn-bg: var(--secondary);
  --tag-bg: var(--secondary);
  --accent-bg: var(--secondary);
}
.green {
  --text-c: var(--green);
  --btn-bg: var(--green);
  --tag-bg: var(--green);
  --accent-bg: var(--green);
}
.blue {
  --text-c: var(--blue);
  --btn-bg: var(--blue);
  --tag-bg: var(--blue);
  --accent-bg: var(--blue);
}
.orange {
  --text-c: var(--orange);
  --btn-bg: var(--orange);
  --tag-bg: var(--orange);
  --accent-bg: var(--orange);
}
.red {
  --text-c: var(--red);
  --btn-bg: var(--red);
  --tag-bg: var(--red);
  --accent-bg: var(--red);
}
.white {
  --text-c: var(--white);
  --btn-bg: var(--white);
  --btn-c: var(--text-main);
  --tag-bg: var(--white);
  --tag-c: var(--text-main);
  --accent-bg: var(--white);
}
.grey {
  --text-c: var(--grey);
  --btn-bg: var(--grey);
  --btn-c: var(--text-main);
  --tag-bg: var(--grey);
  --tag-c: var(--text-main);
  --accent-bg: var(--grey);
}
.black {
  --text-c: var(--black);
  --btn-bg: var(--black);
  --tag-bg: var(--black);
  --accent-bg: var(--black);
}
.accent {
  color: var(--accent-c, var(--text-main));
  background-color: var(--accent-bg, var(--white));
  border: var(--accent-border, 0);
  border-radius: var(--border-radius, 0);
  box-shadow: var(--accent-shadow, none);
  overflow: hidden;
}
.accent.primary,
  .accent.secondary,
  .accent.green,
  .accent.blue,
  .accent.red,
  .accent.orange,
  .accent.black {
    --accent-c: var(--white);
    --text-c: var(--white);
  }
@media only screen and (min-width:440px) {
  .grid[style*="--col-xs:"] {
    grid-template-columns: repeat(var(--col-xs, 1), 1fr);
  }

  .grid > [style*="--sc-xs:"] {
    grid-column: span var(--sc-xs, 1);
  }

  .grid > [style*="--sr-xs:"] {
    grid-row: span var(--sr-xs, 1);
  }
}
@media only screen and (min-width:640px) {
  .grid[style*="--col-sm:"] {
    grid-template-columns: repeat(var(--col-sm, 1), 1fr);
  }

  .grid > [style*="--sc-sm:"] {
    grid-column: span var(--sc-sm, 1);
  }

  .grid > [style*="--sr-sm:"] {
    grid-row: span var(--sr-sm, 1);
  }
}
@media only screen and (min-width:960px) {
  .grid[style*="--col-md:"] {
    grid-template-columns: repeat(var(--col-md, 1), 1fr);
  }

  .grid > [style*="--sc-md:"] {
    grid-column: span var(--sc-md, 1);
  }

  .grid > [style*="--sr-md:"] {
    grid-row: span var(--sr-md, 1);
  }
}
@media only screen and (min-width:1280px) {
  .grid[style*="--col-lg:"] {
    grid-template-columns: repeat(var(--col-lg, 1), 1fr);
  }

  .grid > [style*="--sc-lg:"] {
    grid-column: span var(--sc-lg, 1);
  }

  .grid > [style*="--sr-lg:"] {
    grid-row: span var(--sr-lg, 1);
  }
}