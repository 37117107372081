table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

caption {
  padding: .5rem 0;
  text-align: start;
  caption-side: top;
}

td,
th {
  padding: .5rem;
  text-align: start;
}

th {
  background: rgba(100,100,100,.025);
}

thead {
  border-bottom: 1px solid rgba(100,100,100,.2);
}

tr + tr,
tfoot {
  border-top: 1px solid rgba(100,100,100,.2);
}
